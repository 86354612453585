<template>
  <div class="tw-pb-24">
    <PageHeader title="Overzicht kosten" />

    <div class="tw-my-6 tw-mx-auto">
      <FormulateForm
        :key="searchKey"
        v-model="filters"
        name="filterCosts"
        debounce
        class="tw-px-3 tw-border-b"
      >
        <div class="tw-flex tw-flex-wrap tw-gap-4">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="companies"
            :multiple="true"
            name="companies"
            label="Vennootschappen"
            placeholder="Selecteer vennootschappen"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="user-offices"
            :multiple="true"
            name="offices"
            label="Kantoren"
            placeholder="Selecteer kantoren"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-m-0"
          />

          <fieldset class="fieldset-split-range !tw-my-0">
            <legend class="!tw-text-xs !tw-mb-1">Gemaakt op</legend>
            <FormulateInput
              type="date"
              name="created_on_after"
              validation="bail|optional|date:YYYY-MM-DD"
              :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="date"
              name="created_on_before"
              validation="bail|optional|date:YYYY-MM-DD"
              :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
              outer-class="tw-m-0"
            />
          </fieldset>

          <FormulateInput
            type="select"
            name="advance_received"
            label="Voorschot ontvangen"
            :options="{
              '': 'Alle',
              true: 'Ja',
              false: 'Nee'
            }"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />

          <FormulateInput
            type="select"
            name="transaction_type"
            label="Transactietype"
            placeholder="Selecteer transactietype"
            :options="PROPERTY_TRANSACTION_TYPES"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />

          <FormulateInput
            type="select"
            name="product"
            label="Kost"
            placeholder="Selecteer kost"
            :options="products"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />

          <FormulateInput
            type="select"
            name="status"
            label="Status"
            placeholder="Selecteer pandstatus"
            :options="PROPERTY_STATUSES"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />
        </div>

        <div class="tw-my-4 tw-text-sm tw-font-semibold tw-flex tw-flex-wrap tw-gap-4">
          <router-link
            :to="{ query: { filters: JSON.stringify(filters) } }"
            class="link hover:tw-text-opacity-80"
          >
            <i class="fas fa-search" /> Zoeken
          </router-link>
          <a
            class="link !tw-text-danger hover:!tw-text-opacity-80"
            @click="resetFilters"
          >
            <i class="fas fa-times" /> Filters wissen
          </a>
        </div>
      </FormulateForm>
    </div>

    <DataTable
      v-show="showDataTable"
      :loading="loading"
      :headers="headers"
      :infinite-scroll="true"
      v-bind="costs"
      @infiniteLoad="infiniteLoad"
    >
      <template #toolbar>
        <div class="tw-mb-2 tw-flex tw-justify-end">
          <button
            type="button"
            title="Exporteer de resultaten als csv"
            :disabled="exporting"
            class="action tw-bg-success tw-mr-0"
            @click="exportAsCsv"
          >
            <i
              :class="[
                'fas',
                exporting
                  ? 'fa-spinner-third fa-spin'
                  : 'fa-external-link'
              ]"
            />
            Exporteren
          </button>
        </div>
      </template>

      <template #item-entity_reference="{ item }">
        <EntityHoverable :value="item.entity" />
      </template>
      <template #item-created_on="{ item }">
        {{ item.created_on | date-day }}
      </template>
      <template #item-amount="{ item }">
        {{ currency(item.amount) }}
      </template>
      <template #item-discount_amount="{ item }">
        {{ currency(item.discount_amount) }}
      </template>

      <template #item-ovab_due_date="{ item }">
        <span v-if="item.transaction_data && item.transaction_data.ovab_due_date">
          {{ item.transaction_data.ovab_due_date | date-day }}
        </span>
      </template>

      <template #item-entity_deed_date="{ item }">
        <span v-if="item.entity && item.entity.deed_date">
          {{ item.entity.deed_date | date-day }}
        </span>
      </template>

      <template #item-entity_is_deed_signed="{ item }">
        <i
          v-if="item.entity && item.entity.is_deed_signed"
          class="fas fa-check-circle tw-text-success"
        />
      </template>

      <template #item-advance_amount="{ item }">
        <span v-if="item.advance">
          {{ currency(item.advance.amount) }}
        </span>
      </template>

      <template #item-advance_received="{ item }">
        <div v-if="item.advance">
          <i
            :class="[
              'fas',
              item.advance.received
                ? 'fa-check-circle tw-text-success'
                : 'fa-clock tw-text-warning'
            ]"
          />
          <span v-if="!item.advance.received">
            ({{ currency(item.advance.balance) }})
          </span>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import merge from 'lodash/merge'
import { successModal, errorModal } from '@/modalMessages'
import { currency, exportAsCsvHelper } from '@/utils/helpers'
import { PROPERTY_STATUSES, PROPERTY_TRANSACTION_TYPES } from '@/forms/selectOptions'

import DataTable from '@/components/DataTable'
import PageHeader from '@/components/PageHeader.vue'
import EntityHoverable from '@/components/iam/EntityHoverable'

import { getCosts } from '@/services/costs'
import { getProducts } from '@/services/apiService'

const DEFAULT_FILTERS = {
  overview: true,
  is_settled: false,
  is_invoiceable: true
}

export default {
  name: 'TransactionListCosts',
  components: {
    DataTable,
    PageHeader,
    EntityHoverable
  },
  constants: {
    PROPERTY_STATUSES,
    PROPERTY_TRANSACTION_TYPES
  },
  data () {
    return {
      loading: false,
      searchKey: 'init',
      filters: { ...DEFAULT_FILTERS },
      costs: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      showDataTable: false,
      exporting: false,
      products: []
    }
  },
  computed: {
    headers () {
      const transactionType = parseInt(this.filters?.transaction_type)

      // Verhuurdossier
      const isRentalFile = transactionType === 3

      // Verkoopdossier or Overnamedossier
      const isSalesOrTakeoverFile = [2, 4].includes(transactionType)

      return [
        { text: 'Kantoor', value: 'entity.office_reference' },
        { text: 'Referentie', value: 'entity_reference' },
        { text: 'Status', value: 'entity.status' },
        { text: 'Transactietype', value: 'entity.transaction_type' },
        { text: 'Dossierfase', value: 'entity.stage' },
        { text: 'Datum kost', value: 'created_on' },
        { text: 'Kost', value: 'product' },
        { text: 'Bedrag excl. btw', value: 'amount' },
        { text: 'Korting excl. btw', value: 'discount_amount' },

        ...isSalesOrTakeoverFile
          ? [
            { text: 'OV', value: 'transaction_data.suspensive_condition' },
            { text: 'OV vervaldag', value: 'ovab_due_date' },
            { text: 'Aktedatum', value: 'entity_deed_date' },
            { text: 'Akte verleden', value: 'entity_is_deed_signed' }
          ]
          : [],

        { text: 'Voorschot', value: 'advance_amount' },
        { text: 'Voorschot voldaan', value: 'advance_received' },

        ...isRentalFile
          ? [
            { text: 'Datum ondertekening overeenkomst', value: 'entity.agreement_signature_date' },
            { text: 'Datum ingang huurovereenkomst', value: 'entity.agreement_rental_start_date' },
            { text: 'Verhuurformule', value: 'entity.rental_management_type' }
          ]
          : []
      ]
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value) {
        this.clearValues()

        if (!value.filters) {
          this.showDataTable = false
          return false
        }

        this.showDataTable = true
        const parsedFilters = JSON.parse(value.filters)
        const mergedFilters = merge(this.filters, parsedFilters)
        this.filters = Object.assign({}, this.filters, mergedFilters)

        this.queryCosts()
      }
    }
  },
  created () {
    this.loadProducts()
  },
  methods: {
    currency,
    parseParams ({ companies, offices, ...payload }) {
      // Checklist is actually just parent category that isn't really used in the filtering.
      const params = { ...payload }
      params.companies = companies?.map(company => company.id) || []
      params.offices = offices?.map(office => office.id) || []
      // Do not filter on advance_received if the '' option is selected
      if (!params.advance_received) delete params.advance_received
      return params
    },
    clearValues () {
      this.searchKey = Math.random() // Reset form and it's values
      // set filters to default value
      this.filters = { ...DEFAULT_FILTERS }
      this.costs = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    },
    resetFilters () {
      this.clearValues()
      this.$router.push({ query: {} }).catch(() => {})
    },

    async loadProducts () {
      const response = await getProducts()
      const products = response?.data?.results || []
      this.products = products.map(({ name, id }) => {
        return { label: name, value: id }
      })
      return response
    },
    async loadCosts (payload) {
      const response = await getCosts(payload)
      return response.data
    },
    async queryCosts (data = this.filters) {
      this.loading = true
      const params = this.parseParams(data)
      const costs = await this.loadCosts({ params })
      this.costs = costs
      this.loading = false
      return costs
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.costs.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const costs = await this.loadCosts({ url: next })
        const results = [...this.costs.results, ...costs.results]
        this.costs = { ...costs, results }
        $infinite.loaded()
        return costs
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async exportAsCsv () {
      try {
        this.exporting = true
        const search_params = this.parseParams(this.filters)
        const pollResult = await exportAsCsvHelper({ type: 'costs', search_params })
        successModal(`Succesvol ${this.costs.count} records geëxporteerd.`)
        return pollResult
      } catch (error) {
        errorModal('Kan de kosten niet exporteren, probeer het opnieuw.')
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>
